import * as React from "react";
import Layout from "../components/layout";
import ReservationPanel from "../components/reservation/reservationPanel";

const ReservationPage = () => {
  return (
    <Layout pageTitle="Reservationen">
      <div className="container mx-auto px-4 mt-8">
        <h2>Reservationen</h2>
        <ReservationPanel></ReservationPanel>
      </div>
    </Layout>
  );
};

export default ReservationPage;
